<script setup>
import {PerfectScrollbar} from 'vue3-perfect-scrollbar'
import {injectionKeyIsVerticalNavHovered, useLayouts} from '@layouts'
import {
  VerticalNavGroup,
  VerticalNavLink,
  VerticalNavSectionTitle,
} from '@layouts/components'
import {config} from '@layouts/config'
import {computed, provide, ref, watch} from 'vue'
import {useElementHover, useWindowSize} from '@vueuse/core'
import {useRoute} from 'vue-router'
import {useUserStore} from '@/stores/user'
import {
  getWhiteLabelPortalLogo, getWhiteLabelSidebarLogo,
  isBionicSite,
} from '@/utils/whiteLabelHelpers'

const props = defineProps({
  tag: {
    type: [String, null],
    required: false,
    default: 'aside',
  },
  navItems: {
    type: null,
    required: true,
  },
  isOverlayNavActive: {
    type: Boolean,
    required: true,
  },
  toggleIsOverlayNavActive: {
    type: Function,
    required: true,
  },
})

const refNav = ref()
const {width: windowWidth} = useWindowSize()
const isHovered = useElementHover(refNav)

const navBarItem = computed(() => {
  const isWhiteLabelCustomer = userStore.isWLCustomer
  return props.navItems.filter((item) => {
    if (item.to?.name === 'support' && userStore.wlPlatformOfCustomer?.hide_support_form) {
      return false
    } else {
      return isBionicSite()
        ? !(
          userStore.isWLOwnerAndLoggedInAsCustomer &&
              item.to?.name === 'white-label'
        )
        : !(
          (isWhiteLabelCustomer) &&
              (item.to?.name === 'affiliate' || item.to?.name === 'white-label' || item.title === 'Knowledge Base' || (
                userStore.wlPlatformOfCustomer?.hide_support_form && item.to?.name === 'support'
              ))
        )
    }
  })
})

provide(injectionKeyIsVerticalNavHovered, isHovered)

const {
  isVerticalNavCollapsed: isCollapsed,
  isLessThanOverlayNavBreakpoint,
  isVerticalNavMini,
  isAppRtl,
} = useLayouts()
const userStore = useUserStore()
const userHasUnpaidInvoice = computed(() => userStore.userHasUnpaidInvoice)
const hideTitleAndIcon = isVerticalNavMini(windowWidth, isHovered)

const resolveNavItemComponent = (item) => {
  if ('heading' in item) return VerticalNavSectionTitle
  if ('children' in item) return VerticalNavGroup

  return VerticalNavLink
}

const route = useRoute()

watch(
  () => route.name,
  () => {
    props.toggleIsOverlayNavActive(false)
  },
)

const isVerticalNavScrolled = ref(false)
const mouseEntered = ref(false)
const updateIsVerticalNavScrolled = (val) => (isVerticalNavScrolled.value = val)

const handleNavScroll = (evt) => {
  isVerticalNavScrolled.value = evt.target.scrollTop > 0
}
</script>

<template>
  <Component
      :is="props.tag"
      ref="refNav"
      class="layout-vertical-nav"
      :class="[
      {
        'overlay-nav': isLessThanOverlayNavBreakpoint(windowWidth),
        visible: isOverlayNavActive,
        scrolled: isVerticalNavScrolled,
      },
    ]"
      @mouseenter="mouseEntered = true"
      @mouseleave="mouseEntered = false"
  >
    <!-- 👉 Header -->
    <div class="nav-header">
      <slot name="nav-header">
        <RouterLink
            to="/"
            class="app-logo d-flex align-center gap-x-3 app-title-wrapper w-100"
            :class="{
          'ml-auto': isCollapsed
            }"
        >
          <div
              v-if="!isCollapsed"
              class="d-flex align-items-center justify-content-center h-100 w-100">
            <img
                :src="getWhiteLabelSidebarLogo() || getWhiteLabelPortalLogo()"
                style="max-height: 60px; max-width: 230px;"
                alt="logo"
                class=""
            >
          </div>
          <div v-else class="d-flex align-items-center justify-content-center">
            <img
                v-if="isBionicSite()"
                src="/bwp-bolt-only.gif"
                style="width: 28px; sheight: 28px; "
                alt="logo"
                class="img-fluid"
            >
          </div>
        </RouterLink>
        <!-- 👉 Vertical nav actions -->
        <!-- Show toggle collapsible in >md and close button in <md -->
        <div
            v-if="!isLessThanOverlayNavBreakpoint(windowWidth)"
            :style="{
          position: 'absolute',
          right: '0',
          transform: 'translateX(50%)',
          width: '26px',
          height: '26px',
        }">
          <div
              :style="{
           height: '26px',
           width: '26px',
           backgroundColor: '#fff',
           boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
           borderRadius: '50%',
          }">
            <Component
                :is="config.app.iconRenderer || 'div'"
                v-show="isCollapsed"
                class="header-action"
                v-bind="config.icons.verticalNavUnPinned"
                :style="{
                  height: '14px',
                  width: '14px',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }"
                color="#032140"
                @click="isCollapsed = !isCollapsed"
            />
            <Component
                :is="config.app.iconRenderer || 'div'"
                v-show="!isCollapsed"
                class="header-action"
                color="#032140"
                v-bind="config.icons.verticalNavPinned"
                :style="{
                  height: '14px',
                  width: '14px',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }"
                @click="isCollapsed = !isCollapsed"
            />
          </div>
        </div>
        <div v-else>
          <Component
              :is="config.app.iconRenderer || 'div'"
              class="header-action"
              v-bind="config.icons.close"
              @click="toggleIsOverlayNavActive(false)"
          />
        </div>
      </slot>
    </div>
    <slot name="before-nav-items">
      <div :style="{ height: '1px', width: '100%', backgroundColor: 'rgb(var(--v-theme-layout-gridlines))', padding: '0', margin: '0'}"></div>
      <VSpacer class="my-2"></VSpacer>

      <!--      <VDivider-->
      <!--        class="mx-auto my-2"-->
      <!--        :class="!isCollapsed || isHovered ? 'w-75' : 'w-50'"-->
      <!--      />-->
    </slot>
    <slot
        name="nav-items"
        :update-is-vertical-nav-scrolled="updateIsVerticalNavScrolled"
    >
      <PerfectScrollbar
          :key="isAppRtl"
          tag="ul"
          class="nav-items"
          :options="{ wheelPropagation: false }"
          @ps-scroll-y="handleNavScroll"
      >
        <Component
            :is="resolveNavItemComponent(item)"
            v-for="(item, index) in navBarItem"
            :key="index"
            :item="item"
            :is-collapsed="isCollapsed"
        />
      </PerfectScrollbar>
    </slot>
    <div
        class="text-center mb-3"
        :class="userHasUnpaidInvoice ? 'cursor-not-allowed' : ''"
    >
      <div
          v-if="!isCollapsed"
          class="mx-5"
      >
        <VBtn
            id="add-new-site-btn"
            :class="
            userHasUnpaidInvoice ? 'disabled-button cursor-not-allowed' : ''
          "
            to="/sites/add"
            class="w-100"
            prepend-icon="tabler-plus"
        >
          Add New Site
        </VBtn>
      </div>
      <VBtn
          v-else
          id="add-new-site-btn"
          icon
          size="40"
          :class="userHasUnpaidInvoice ? 'disabled-button' : ''"
      >
        <VIcon icon="mdi-plus"/>
      </VBtn>
    </div>
    <div
        class="text-center mb-2"
        :class="userHasUnpaidInvoice ? 'cursor-not-allowed' : ''"
    >
      <div
          v-if="!isCollapsed"
          class="mx-5"
      >
        <VBtn
            id="request-migration-btn"
            :class="userHasUnpaidInvoice ? 'disabled-button' : ''"
            to="/sites/add?tab=migration"
            prepend-icon="v2-icons:req-migration"
            class="w-100"
        >
          Request Migration
        </VBtn>
      </div>
      <VBtn
          v-else
          id="request-migration-btn"
          size="40"
          icon
          :class="userHasUnpaidInvoice ? 'disabled-button' : ''"
      >
        <VIcon icon="v2-icons:req-migration"/>
      </VBtn>
    </div>
  </Component>
</template>

<style lang="scss">
@use '@configured-variables' as variables;
@use '@layouts/styles/mixins';

// 👉 Vertical Nav
.layout-vertical-nav {
  position: fixed;
  z-index: variables.$layout-vertical-nav-z-index;
  display: flex;
  flex-direction: column;
  block-size: 100%;
  inline-size: variables.$layout-vertical-nav-width;
  inset-block-start: 0;
  inset-inline-start: 0;
  transition: transform 0.25s ease-in-out, inline-size 0.25s ease-in-out,
  box-shadow 0.25s ease-in-out;
  will-change: transform, inline-size;

  .nav-header {
    display: flex;
    align-items: center;

    .header-action {
      cursor: pointer;
    }
  }

  .app-title-wrapper {
    margin-inline-end: auto;
  }

  .nav-items {
    block-size: 100%;

    // ℹ️ We no loner needs this overflow styles as perfect scrollbar applies it
    // overflow-x: hidden;

    // // ℹ️ We used `overflow-y` instead of `overflow` to mitigate overflow x. Revert back if any issue found.
    // overflow-y: auto;
  }

  .nav-item-title {
    overflow: hidden;
    color:rgb(var(--v-theme-on-primary))!important;
    margin-inline-end: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 400;
    font-size: 16px; // 1rem
  }

  // 👉 Collapsed
  .layout-vertical-nav-collapsed & {
    &:not(.hovered) {
      inline-size: variables.$layout-vertical-nav-collapsed-width;
    }
  }

  // 👉 Overlay nav
  &.overlay-nav {
    &:not(.visible) {
      transform: translateX(-#{variables.$layout-vertical-nav-width});

      @include mixins.rtl {
        transform: translateX(variables.$layout-vertical-nav-width);
      }
    }
  }
}

.disabled-button {
  pointer-events: none;
  opacity: 0.5;
}

.cursor-not-allowed {
  cursor: not-allowed;
}
</style>
