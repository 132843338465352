<script setup lang="ts">
import WelcomeUser from '@/views/dashboard/WelcomeUser.vue'
import ResourceUsage from '@/views/dashboard/ResourceUsage.vue'
import WebsiteInfo from '@/views/dashboard/WebsiteInfo.vue'
import PerformanceManagement from '@/views/dashboard/PerformanceManagement.vue'
import PSIStatsInfo from '@/views/dashboard/PSIStatsInfo.vue'

import { onBeforeMount } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()
onBeforeMount(() => {})
</script>

<template>
  <VRow>
    <!-- 👉 Congratulation John -->
    <VCol cols="12" md="6" lg="6" class="gap-4">
      <welcome-user class="pb-3" />
    </VCol>

    <!-- 👉 Website info -->
    <VCol cols="12" md="6" lg="6">
      <website-info />
    </VCol>

    <!-- 👉 Resource Usage -->
    <VCol cols="12" md="12" lg="12">
      <ResourceUsage class="mt-5" />
    </VCol>

    <VCol cols="12" md="12" lg="12">
      <PerformanceManagement class="mt-3" />
    </VCol>
    <VCol cols="12" md="12" lg="12">
      <PSIStatsInfo class="mt-6" />
    </VCol>
  </VRow>
</template>
<!-- @formatter:off-->
<route lang="yaml">
meta:
  title: Dashboard
  redirectTo: /sites
</route>
