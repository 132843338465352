<script setup>
import { useLayouts } from '@layouts'
import { config } from '@layouts/config'
import { can } from '@layouts/plugins/casl'
import { getComputedNavLinkToProp, isNavLinkActive } from '@layouts/utils'

import { useWindowSize } from '@vueuse/core'
import { useTheme } from 'vuetify'
import { ElementIDs } from '@/common/enums/elementIdsEnum'
import themeConf from '../../plugins/vuetify/theme'
const props = defineProps({
  item: {
    type: null,
    required: true,
  },
  isCollapsed: {
    type: Boolean,
    required: false,
  },
})

const theme = useTheme()

const { width: windowWidth } = useWindowSize()
const { isVerticalNavMini, dynamicI18nProps } = useLayouts()
const hideTitleAndBadge = isVerticalNavMini(windowWidth)
</script>

<template>
  <li
    v-if="can(item.action, item.subject)"
    class="nav-link"
    :class="{ disabled: item.disable }"
  >
    <Component
      :is="item.to ? 'RouterLink' : 'a'"
      v-bind="getComputedNavLinkToProp(item)"
      :id="
        item.id ||
          `${ElementIDs.SIDEBAR_NAV._base}-${
            item.to?.name || item.title.toLowerCase()
          }`
      "
      :class="{
        'router-link-active router-link-exact-active': isNavLinkActive(
          item,
          $router,
        ),
      }"
    >
      <Component
        :is="config.app.iconRenderer || 'div'"
        v-bind="item.icon || config.verticalNav.defaultNavItemIconProps"
        color="icons-color"
        size="24"
        class="nav-item-icon"
      />
      <TransitionGroup name="transition-slide-x">
        <!-- 👉 Title -->
        <Component
          :is="config.app.enableI18n ? 'i18n-t' : 'span'"
          v-show="!hideTitleAndBadge"
          key="title"
          :style="{
            color: theme.current.value.colors?.['on-background'] + '!important'
          }"
          class="nav-item-title"
          v-bind="dynamicI18nProps(item.title, 'span')"
        >
          {{ item.title }}
        </Component>

        <!-- 👉 Badge -->
        <Component
          :is="config.app.enableI18n ? 'i18n-t' : 'span'"
          v-if="item.badgeContent"
          v-show="!hideTitleAndBadge"
          key="badge"
          class="nav-item-badge"
          :class="item.badgeClass"
          v-bind="dynamicI18nProps(item.badgeContent, 'span')"
        >
          {{ item.badgeContent }}
        </Component>

        <!-- 👉 Badge -->
        <Component
          :is="config.app.enableI18n ? 'i18n-t' : 'span'"
          v-if="item.chip"
          key="chip"
          v-bind="dynamicI18nProps(item.chip, 'span')"
        >
          <VChip
              v-show="!isCollapsed"
            :color="item.chip.color ?? 'primary'"
            :size="item.chip.size ?? 'small'"
          >
            <span style="font-weight: 400; font-size: 12px; ">{{ item.chip.text }}</span>
          </VChip>
        </Component>
        <VIcon v-if="!!item.href" size="16">
          tabler-external-link
        </VIcon>
      </TransitionGroup>
    </Component>
  </li>
</template>

<style lang="scss">
.layout-vertical-nav {
  .nav-link a {
    display: flex;
    align-items: center;
  }
}
</style>